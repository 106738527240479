<template>
  <div class="st" @click="rightBarHandle()">
    <img src="../assets/images/icon/common/cart.svg" style="width: 35px;height: 35px" alt="">
    <div class="cart_item_count">
      {{$store.state.sportsCartItems.length}}
    </div>
  </div>
</template>

<script>
  export default {
    name: "BetCartIconSportsComp",
    methods: {
      rightBarHandle() {
        this.$bus.$emit('rightBarHandle', true)
      },
    }
  }
</script>

<style scoped>
  .st {
    width: 50px;
    height: 50px;
    font-size: 40px;
    background-color: var(--scrolltopbg);
    border-radius: 50%;
    position: fixed;
    bottom: 13%;
    right: 25%;
    z-index: 10;
    text-align: center;
    cursor: pointer;
    opacity: .9;
    box-shadow: rgba(0, 0, 0, 0.6) -3px 6px 6px -3px;
    display: none;
  }
  .st i{
    line-height: 50px;
    color: #ffffff;
  }
  .cart_item_count {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    left: calc(50% + 2px);
    padding: 3px;
    border-radius: 50%;
    background-color: #f22755;
    font-size: 10px;
    color: #fff9ff;
  }
  @media screen and (max-width:1024px) {
    .st {
      right: 5%;
      bottom: 6%;
      width: 40px;
      height: 40px;
      font-size: 20px;
      display: flex!important;
      justify-content: center;
      align-items: center;
      border: 2px solid #ffa500;
    }
    .st i{
      line-height: 40px;
      color: #ffffff;
    }
  }
</style>